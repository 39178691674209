import React from 'react'
import {Link} from 'react-router-dom'


const Webpurifynav = () => {
    return (
        <div>
            <div class="navbar">
                <a href="/">
                <img STYLE="position:absolute; TOP:35px; LEFT:100px; WIDTH:200px; HEIGHT:50px" SRC="https://static.aadhan.in/img/shorts/aadhan-logo-white.png"/>
                </a>
                
                
                <div class="dropdown">
                    <button class="dropbtn">Products 
                        <i class="fa fa-caret-down"></i>
                    </button>
                <div class="dropdown-content">
                    <nav>
                        <Link to="/objectdetection">Object Detection</Link>
                    </nav>
                    <nav>
                        <Link to="/webpurify">Web Purify</Link>
                    </nav>
                    <nav>
                        <Link to="/Nudity">Nudity Detection</Link>
                    </nav>
                    <nav>
                        <Link to="/genderdetection">Gender Detection</Link>
                    </nav>
                </div>
                </div> 
                
            </div>
            
        </div>
    )
}

export default Webpurifynav
