import Hearder from "./components/Hearder";
import Nav from "./components/Nav";
import Object from "./components/Object";
import Webpurify from "./components/Webpurify";
import Genderdetection from "./components/Genderdetection";
import Nudity from "./components/Nudity";
import { BrowserRouter as Router,Routes, Route,Switch, Link } from "react-router-dom";


function App() {
  
  return (
    
    <div className="App">
     
      <Routes>
        <Route path="/" element={<Nav />} />
        <Route path="objectdetection" element={<Object />} />
        <Route path="webpurify" element={<Webpurify />} />
        <Route path="genderdetection" element={<Genderdetection />} />
        <Route path="nudity" element={<Nudity />} />
        <Route path="test" element={<Hearder />} />
      </Routes>
    </div>
    

  );
}

export default App;
