import React from 'react'

const Hearder = () => {
  return (
    <div >
      <h2>faknvskvnso</h2>
      <h2>faknvskvnso</h2>
      <h2>faknvskvnso</h2>
      <h2>faknvskvnso</h2>
    </div>
  )
}

export default Hearder
