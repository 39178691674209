import React ,{ useState }from 'react'
import Webpurifynav from './Webpurifynav'

const Genderdetection = () => {
    const [path,setPath] = useState({video_path:''});
    const [result,setResult] = useState({answer:''});
    const [load,setLoad] = useState(false);
    const api = async(path) => {
        setLoad(true)
        console.log(typeof(path.video_url))
        const ans = await fetch('https://mlapi.jalsa.net/genderdetection/', {
            body: JSON.stringify(path),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST'
            })
        console.log(ans) 
        setResult({answer:ans})
        setLoad(false)
    }
    const submitHandler = e => {
        e.preventDefault()
        api(path)
        
    }
    return (
        <div >
            <Webpurifynav />
            <div class = 'objecttext'>
                <h1>AI-based Gender Detection service.</h1>
                <h1 >Detect Gender Using DEEP-LEARNING TECHNOLOGY</h1>
                <div>
                    <form onSubmit ={submitHandler}>
                        <div class = 'objmodel'>
                        <label htmlFor="text">video_url:</label>
                        <input type = 'text' name = 'text' onChange = {e => setPath({video_path:e.target.value})} value = {path.video_path}/>
                        </div>
                        <input class = 'inputobj' type="submit" value="submit" />
                    </form>
                </div>
                
            </div>
            
        </div>
    )
}


export default Genderdetection
