import React ,{ useState }from 'react'
import Webpurifynav from './Webpurifynav'


const Webpurify = () => {
    const [path,setPath] = useState({text:''});
    const [result,setResult] = useState({answer:''});
    const [load,setLoad] = useState(false);
    const api = async(path) => {
        setLoad(true)
        console.log(JSON.stringify(path))
        const ans = await fetch('https://mlapi.jalsa.net/webpurify', {
            method: 'POST',
            body: path,
            mode: 'no-cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            
            })
        console.log(ans) 
        setResult({answer:ans})
        setLoad(false)
    }
    const submitHandler = e => {
        e.preventDefault()
        api(path)
        
    }
    return (
        <div >
            <Webpurifynav />
            <div class = 'objecttext'>
                <h1>Profanity Filtering & Offensive Intent Detection. Technology You Can Swear By</h1>
                
                <div>
                    <form onSubmit ={submitHandler}>
                        <div class = 'objmodel'>
                            <label htmlFor="text">Text You Want To Filter:</label>
                            <input  type = 'text' name = 'text' onChange = {e => setPath({text:e.target.value})} value = {path.text}/>
                        </div>
                        <input class = 'input' type="submit" value="submit" />
                    </form>
                </div>
                
            </div>
            
        </div>
    )
}


export default Webpurify
